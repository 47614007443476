<template>
  <v-img
    class="d-flex align-center"
    height="89vh"
    :src="require('../../assets/fondo-login.jpg')"
  >
    <v-row class="mx-auto" align="center" justify="center">
      <v-col
        cols="12"
        lg="auto"
        align="center"
        justify="center"
        order-sm-first
        md="auto"
      >
        <v-img :src="require('../../assets/logo.svg')" width="45vh"></v-img>
      </v-col>
      <v-col cols="12" md="2"></v-col>
      <v-col cols="12" align="center" justify="center" order-sm-first md="auto">
        <v-card class="pa-4" color="white" width="300">
          <p class="text-title-1 text-center">
            Ingrese sus datos para iniciar sesión
          </p>
          <v-text-field
            v-model="user.username"
            prepend-inner-icon="mdi-account"
            label="Usuario"
          />
          <v-text-field
            v-model="user.password"
            prepend-inner-icon="mdi-key-variant"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            label="Contraseña"
            @click:append="show1 = !show1"
            @keydown.enter="handleLogin()"
          />
          <v-card-actions>
            <v-row align="center" justify="center">
              <v-btn text @click="handleLogin()">iniciar sesión</v-btn>
            </v-row>
            <!-- <v-row align="center" justify="center">
              <v-btn text @click="handleLogin()">iniciar sesión como invitado</v-btn>
            </v-row> -->
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="wrong_pass" width="50%">
      <v-alert border="right" colored-border type="warning" elevation="2">
        <v-row align="center">
          <v-col>
            Error!<br /><br />Credenciales no válidas, verifique su usuario y
            contraseña...
          </v-col>
        </v-row>
        <br />
        <v-row align="center" justify="center">
          <v-btn plain @click="loginGuest()">ingresar como invitado</v-btn>
        </v-row>
      </v-alert>
    </v-dialog>
  </v-img>
</template>

<script>
import User from "../../models/user";
export default {
  data: () => ({
    show1: false,
    user: new User("", ""),
    message: "",
    wrong_pass: false,
  }),
  watch: {},
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      let user_ = JSON.parse(sessionStorage.getItem("user"));
      if (user_.roles.includes("ROLE_TERCERO"))
        this.$router.push("/cotizacion");
      this.$router.push("/stock");
    }
  },
  methods: {
    handleLogin() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        if (this.user.username && this.user.password) {
          this.$store.dispatch("auth/login", this.user).then(
            () => {
              let user_ = JSON.parse(sessionStorage.getItem("user"));
              if (user_.roles.includes("ROLE_TERCERO")) {
                this.$router.push("/cotizacion");
              } else {
                this.$router.push("/stock");
              }
            },
            (error) => {
              this.wrong_pass = true;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
        } else {
          this.wrong_pass = true;
        }
      });
    },
    loginGuest() {
      this.user.username = "invitado";
      this.user.password = "invitado";
      this.handleLogin();
    },
  },
};
</script>
